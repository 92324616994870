





































export default {
  name: "Booked",
  props: {
    bookingInfo: {},
    hotelInfo: {}
  }
};
