





































export default {
  name: "CurrentlyStaying",
  props: {
    bookingInfo: {},
    hotelInfo: {}
  }
};
