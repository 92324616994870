
















































export default {
  name: "ArrivingSoon",
  props: {
    bookingInfo: {},
    hotelInfo: {}
  },

  methods: {
    close: function(e) {
      if (e.target.parentElement) {
        e.target.parentElement.classList.add("hidden");
      }
    }
  }
};
