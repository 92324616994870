





































export default {
  name: "Departed",
  props: {
    bookingInfo: {},
    hotelInfo: {}
  }
};
