

































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Booked from "@/components/bookingState/Booked.vue";
import ArrivingSoon from "@/components/bookingState/ArrivingSoon.vue";
import CurrentlyStaying from "@/components/bookingState/CurrentlyStaying.vue";
import Departed from "@/components/bookingState/Departed.vue";
import axios from "@/axios";

@Component({
  components: {
    Booked,
    ArrivingSoon,
    CurrentlyStaying,
    Departed
  }
})
export default class GuestInfo extends Vue {
  data() {
    return {
      errors: []
    };
  }

  // Gets Hotel Data & Booking Data
  getGuestData() {
    if (!this.authUser) return;
    const userKey = this.$route.params.UrlKey;
    if (!userKey) {
      this.$store.commit("removeBookingInfo", false);
      return;
    }
    const Url = `${process.env.VUE_APP_APIURL}/bookings/${userKey}`;
    const data = { e: this.authUser ? btoa(this.authUser.email) : null };
    axios
      .post(Url, data)
      .then(response => {
        this.$store.dispatch("setGuestBookingInfo", response.data).then(() => {
          this.$store.commit("setUILoading", false);
          this.$store.commit("setUnreadMessages", true);
        });
      })
      .catch(e => {
        console.log("error", e);
      });
  }

  get userAuthenticated() {
    return true;
  }

  get bookingInfo() {
    return this.$store.state.bookingInfo;
  }

  get hotelInfo() {
    return this.$store.state.hotelInfo;
  }

  get authUser() {
    return this.$store.state.authProfile;
  }

  callUs(hotelData) {
    if (hotelData.telephone) {
      const Link = `tel:${hotelData.telephone}`;
      window.location.href = Link;
    }
  }

  gotoGuestReg(bookingInfo) {
    if (bookingInfo.guestRegLink) {
      //window.location.href = bookingInfo.guestRegLink;
      window.open(bookingInfo.guestRegLink);
    }
  }

  emailUs(hotelData) {
    if (hotelData.guideemail) {
      const Link = `mailto:${hotelData.guideemail}`;
      window.location.href = Link;
    }
  }

  googleDirection(hotelData) {
    console.log(hotelData);
    if (hotelData.formatAddress) {
      let googleLink = "https://www.google.com/maps/dir/Current+Location/";
      googleLink += hotelData.formatAddress;
      window.open(googleLink, "_blank");
    }
  }

  // Pushes posts to the server when called.
  created() {
    this.getGuestData();
  }
}
